import DataTable from 'datatables.net-bs5'

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []
  
  initialize() {
    // $("#oc-table-with-locations").dataTable({
    //   retrieve: true,
    //   paging: false,
    //   searching: false,
    //   scrollY: 300,
    //   order: [6, 'desc']});

    $("#oc-table").dataTable({
      retrieve: true,
      paging: false,
      searching: false,
      scrollY: 400,
      scrollCollapse: true,
      stateSave: true,
      order: [5, 'desc']});

    $("#documents-table").dataTable({
      retrieve: true,
      paging: false,
      searching: false,
      stateSave: true,
      scrollY: 400,
      scrollCollapse: true});
  }
}
