import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'actionsTable']

  onLocationMigrationActionsUpdate(event) {
    let [data, status, xhr] = event.detail;

    this.actionsTableTarget.innerHTML = xhr.response
  }
}
