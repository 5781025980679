import { Controller } from "@hotwired/stimulus"

require('leaflet/dist/leaflet')
require('leaflet-providers/leaflet-providers')
require('leaflet.sync')
require('leaflet-fullscreen/dist/Leaflet.fullscreen')
import DataTable from 'datatables.net-bs5'
require('datatables.net-scroller' )

import { deserialize } from "deserialize-json-api"

export default class extends Controller {
  static targets = [ 'locationList', 'locationSelector', 'locationForm', 'childLocationForm', 'childLocationError', 'assetImageLeft', 'assetImageRight', 'locationMap', 'countryMap', 'compositePreview']

  initialize() {
    this.maps = {}
    
    if (this.hasLocationMapTarget) {
      this.initializeLocationMaps()
    }
  }

  initializeCountryMaps() {
    $('.map-container').each( (index, element) => {
      console.log(element)
      if ($(element).is(":visible")) {
	console.log(element)
	this.initializeMap(element)
      }
    })			     
  }
  
  initializeLocationMaps() {
    $('.map-container').each((index, element) => {
      let id = $(element).data('location-period-id')
      let link = $(element).data('link') ? $(element).data('link') : null
      let mapName = `location-period-map-${id}`

      this.maps[mapName] = L.map(mapName, {
	fullscreenControl: $(element).data('fullscreen'),
	zoomControl: ! element.classList.contains('small-map'),
	scrollWheelZoom: ! element.classList.contains('small-map'),
	attributionControl: false})
	.fitBounds([ [$(element).data('y-min'),
		      $(element).data('x-min')],
		     [$(element).data('y-max'),
		      $(element).data('x-max')] ])

      if (link) {
	this.maps[mapName].on('click', () => { window.location.href = link })
      }
      
      L.tileLayer.provider('CartoDB.Positron').addTo(this.maps[mapName])

      if ($(element).data('geojson'))
	L.geoJSON($(element).data('geojson'), {style: (feature) => {
          return {color: '#76CCCE', fillOpacity: 0}
	}}).addTo(this.maps[mapName])
    })
  }
  
  updateLocationSelector(event) {
    let form = $(event.srcElement).closest('form').get(0)

    form.elements['selected_location_id'].value = event.srcElement.value

    Rails.fire(form, 'submit')
  }

  onLocationUpdate(e) {
    this.locationListTarget.innerHTML = App.location_list
    this.locationSelectorTarget.innerHTML = App.location_selection_form
  }

  onChildLocationCreationError(event) {
    let [data, status, xhr] = event.detail

    this.childLocationErrorTarget.innerHTML = xhr.response
  }
  
  validateChildLocationForm(event) {
    if (this.childLocationFormTarget.location_region.value.includes("|")) {
      alert('Please add Compsosite Locations using the "Add Composite Location" function.')
      event.preventDefault()
    }
  }

  onLocationPickerChange(event) {
    let form = $(event.srcElement).closest('form')
    let picker = $(event.srcElement).closest('.location-picker')
    let index = picker.data('picker-index')
    let change_depth = $(event.srcElement).closest('.selector-span').find(`[name='${index}#depth']`).val()

    form.append($("<input>")
		.attr("type", "hidden")
		.attr("name", `${index}#change_depth`)
		.val(change_depth))
    picker.find(`[name='${index}#change_depth']`).val(change_depth)

    Rails.fire(form.get(0), 'submit')
  }

  previewCompositeLocationAddComponent(event) {
    event.preventDefault()
    
    let form = $(event.srcElement).closest('form')
    
    form.append($("<input>")
		.attr("type", "hidden")
		.attr("name", `add_location`)
		.val(1))

    Rails.fire(form.get(0), 'submit')
  }

  previewCompositeLocationRemoveComponent(event) {
    event.preventDefault()
    
    let form = $(event.srcElement).closest('form')

    let picker = $(event.srcElement).closest('.location-picker')

    form.append($("<input>")
		.attr("type", "hidden")
		.attr("name", `${picker.data('picker-index')}#remove_location`)
		.val(1))

    Rails.fire(form.get(0), 'submit')
  }

  previewCompositeLocationCreateLocation(event) {
    event.preventDefault()
    
    let form = $(event.srcElement).closest('form')
    
    form.append($("<input>")
		.attr("type", "hidden")
		.attr("name", `create_location`)
		.val(1))

    Rails.fire(form.get(0), 'submit')
  }
  
  onCompositePreviewSuccess(event) {
    let [data, status, xhr] = event.detail

    $('#composite-preview').html(xhr.response)
  }
}
