import ApplicationController from "./application_controller"
import DataTable from 'datatables.net-bs5'
import * as bootstrap from "bootstrap"
import { Modal } from "bootstrap"
import Autocomplete from "bootstrap5-autocomplete"
require('d3')
require('d3-simple-slider/dist/d3-simple-slider')

import { TempusDominus } from '@eonasdan/tempus-dominus'
import * as d3Base from 'd3'
import { responsivefy } from '../responsivefy'
import { sliderBottom } from 'd3-simple-slider';

const d3 = Object.assign(d3Base, { sliderBottom })

export default class extends ApplicationController {
  static targets = [ 'ocFormContainer', 'ocForm', 'ocList', 'ocFilter', 'filterForm', 'errors', 'locationSelectionForm', 'addLocationSpan', 'setLocationButton', 'locationSaveAlert', 'editableObservations', 'epicurve', 'sourceDocumentSearch', 'sourceDocumentUpload', 'openFileListButton', 'closeFileListButton', 'uploadedAlert' ]

  updateOCFormLocation(event) {
    event.srcElement.elements['observation_collection[location_id]'].value = this.locationSelectionFormTarget.elements['location_id'].value
  }
  
  initialize() {
    super.initializeDatepickers()

    if (this.hasSourceDocumentSearchTarget) {
      $(this.sourceDocumentSearchTarget).attr('autocomplete', 'off')

      Autocomplete.init();

      new Autocomplete(this.sourceDocumentSearchTarget,
		     {
		       suggestionsThreshold: 2,
		       server: "/source_documents/search.json",
		       liveServer: true,
		       valueField: 'value',
		       labelField: 'text',
		       onSelectItem: (item) => {
			 Rails.ajax({
			   type: "GET", 
			   url: `/source_documents/${item.value}/pick`,	   
			   success: function (response, status, xhr) {
			     $('#source-document-rows').append(xhr.response) },
			   error: function (response) {}
			 })
		       }
		     })
    }

    if (this.hasSourceDocumentUploadTarget) {
      $(this.sourceDocumentUploadTarget).on('change',function(event) {
        let fileName = event.target.files[0].name

        $(this).next('.custom-file-label').html(fileName);
	$(this).closest('.source-document-upload').find('#source_document_name').val(fileName)
      })
    }

    this.applyDataTable()

    $('.map-container').each((index, element) => {	
      let id = $(element).data('location-period-id')

      let map = L.map(`location-period-map-${id}`).fitBounds([[$(element).data('y-min'), $(element).data('x-min') ],[$(element).data('y-max'), $(element).data('x-max')]])
	
      L.tileLayer.provider('CartoDB.Positron').addTo(map)
      
      if ($(element).data('geojson'))
	L.geoJSON($(element).data('geojson')).addTo(map)
    })

    super.disableAutocompletion()

    if ( this.hasEpicurveTarget ) {
      this.epicurveFigure = {}
      
      this.initializeEpicurve()
      this.renderEpicurve()
    }
  }

  applyDataTable() {
    $("#observation-collections-table").dataTable({
      order: [],
      retrieve: true,
      paging: false,
      searching: false,
      scrollX: '100%',
      //scrollY: 600,
      bInfo: false,
      stateSave: true})

    $("#observations-table").dataTable({
      retrieve: true,
      paging: false,
      searching: false,
      scrollX: true,
      autoWidth : false,
      bInfo: false,
      stateSave: true
    })
  }
  
  
  initializeEpicurve() {
    this.epicurveFigure.margin = {top: 10, right: 30, bottom: 30, left: 40}
    this.epicurveFigure.width = 2560 - this.epicurveFigure.margin.left - this.epicurveFigure.margin.right
    this.epicurveFigure.height = 800 - this.epicurveFigure.margin.top - this.epicurveFigure.margin.bottom
        
    this.epicurveFigure.parseTime = d3.timeParse("%Y-%m-%d")
    this.epicurveFigure.formatTime = d3.timeFormat("%b. %Y")
   
    this.epicurveFigure.svg = d3.select('#epicurve')
      .append("svg")
      .attr("width", this.epicurveFigure.width +
	    this.epicurveFigure.margin.left +
	    this.epicurveFigure.margin.right)
      .attr("height", this.epicurveFigure.height +
	    this.epicurveFigure.margin.top +
	    this.epicurveFigure.margin.bottom)
      .call(responsivefy)
      .append("g")
      .attr("transform", 
	    `translate(${this.epicurveFigure.margin.left}, ${this.epicurveFigure.margin.top})`)

    this.epicurveFigure.summarySvg = d3.select('#locationSummaries')
      .append("svg")
      .attr("width", this.epicurveFigure.width +
	    this.epicurveFigure.margin.left +
	    this.epicurveFigure.margin.right)
      .attr("height", this.epicurveFigure.height +
	    this.epicurveFigure.margin.top +
	    this.epicurveFigure.margin.bottom)
      .call(responsivefy)
      .append("g")
      .attr("transform", 
	    `translate(${this.epicurveFigure.margin.left}, ${this.epicurveFigure.margin.top})`)

    /*this.epicurveFigure.indiSvg = d3.select('#locationEpicurves')
      .append("svg")
      .attr("width", this.epicurveFigure.width +
	    this.epicurveFigure.margin.left +
	    this.epicurveFigure.margin.right)
      .attr("height", this.epicurveFigure.height +
	    this.epicurveFigure.margin.top +
	    this.epicurveFigure.margin.bottom)
      .call(responsivefy)
      .append("g")
      .attr("transform", 
	    `translate(${this.epicurveFigure.margin.left}, ${this.epicurveFigure.margin.top})`)*/
  }

  renderEpicurve(startDate = '', endDate = '') {
    this.epicurveFigure.svg.selectAll('*').remove()
    this.epicurveFigure.summarySvg.selectAll('*').remove()

    d3.json(`/observation_collections/${gon.observation_collection_id}/summary_for_epicurve/${startDate}/${endDate}`)
      .then( (data) => {
	data.summary.forEach( (d) => {
	  d.date = this.epicurveFigure.parseTime(d.time_left)
	})
	
	let numTicks = Math.round(Object.keys(data.summary).length / 25)
	
	let x = d3
	    .scaleBand()
	    .domain(data.summary.map( (d) => d.date) )
	    .rangeRound([this.epicurveFigure.margin.left,
			 this.epicurveFigure.width - this.epicurveFigure.margin.right])
	    .padding(0)
	
	let y = d3
	    .scaleLinear()
	    .domain([0,
		     d3.max(data.summary, (d) => d.suspected_cases)])
	    .range([this.epicurveFigure.height - this.epicurveFigure.margin.bottom,
		    this.epicurveFigure.margin.top])
	
	let len = data.summary.length - 1
	let tickValuesForAxis = data.summary.map( (d, i) => {
	    if (i % numTicks == 0 || i == len) return d.date
	})
	
	this.epicurveFigure.svg
	  .append("g")
	  .attr("fill", "#c13414")
	  .selectAll("rect")
	  .data(data.summary)
	  .join("rect")
	  .attr("x", (d) => x(d.date))
	  .attr("y", (d) => y(d.suspected_cases))
	  .attr("height", (d) => y(0) - y(d.suspected_cases))
	  .attr("width", x.bandwidth)
	
	this.epicurveFigure.svg
	  .append("g")
	  .attr("fill", "#a0a0a0")
	  .selectAll("rect")
	  .data(data.summary)
	  .join("rect")
	  .attr("x", (d) => x(d.date))
	  .attr("y", (d) => y(d.deaths))
	  .attr("height", (d) => y(0) - y(d.deaths))
	  .attr("width", x.bandwidth)
	
	this.epicurveFigure.svg
	  .append("g")
	  .attr("transform", `translate(0, ${this.epicurveFigure.height - this.epicurveFigure.margin.bottom})`)
	  .call(
	    d3
	      .axisBottom(x)
	      .tickValues(tickValuesForAxis)
	      .tickFormat( (d, i) => {
		let r = ""
		if (i % numTicks == 0 || i == len) {
		  r = this.epicurveFigure.formatTime(d)
		}
		return r
	      })
	  )
	
	this.epicurveFigure.svg
	  .append("g")
	  .attr("transform", `translate(0, 20)`)
	  .call( d3.axisLeft(y) )

	if (! this.epicurveFigure.sliderRendered) {
	  this.renderRangeSlider(data.summary)
	}

	let summaryX = d3.scaleBand()
	    .domain(data.location_summary_result.map( (d) => { return d.region }))
            .range([0, this.epicurveFigure.width])
            .padding(0.1)
	
	let summaryY = d3.scaleLinear()
	    .domain([0,
		     d3.max(data.location_summary_result, (d) => d.suspected_cases)])
	    .range([this.epicurveFigure.height - this.epicurveFigure.margin.bottom,
		    this.epicurveFigure.margin.top])

	// append the rectangles for the bar chart
	this.epicurveFigure.summarySvg.selectAll(".bar")
	  .data(data.location_summary_result)
	  .enter().append("rect")
	  .attr("class", "bar")
	  .attr("x", function(d) { return summaryX(d.region); })
	  .attr("width", summaryX.bandwidth())
	  .attr("y", (d) => { return summaryY(d.suspected_cases) })
	  .attr("height", (d) => { return this.epicurveFigure.height - summaryY(d.suspected_cases) })
	  .on("mouseover", function(d, i) {
	    let element = document.getElementById(`mini${i.parent_id}`);

	    element.scrollIntoView()
	  })
	
	this.epicurveFigure.summarySvg.append("g")
	  .attr("transform", "translate(0," + this.epicurveFigure.height + ")")
	  .call(d3.axisBottom(summaryX))
	
	this.epicurveFigure.summarySvg.append("g")
	  .call(d3.axisLeft(summaryY))
    
	var width = 150;
	var height = 120;
	var margin = {top: 25, right: 60, bottom: 40, left: 35};

	// Create the axes
	var xScale = d3.scaleBand()
	    .range([0, width])
	    .padding(0.1)
	
	var yScale = d3.scaleLinear()
	    .range([height, 0])
	
	var xAxis = d3.axisBottom()
	    .scale(xScale)

	// Small multiples
	
	let data_by_locations = d3.group(data.location_epicurves, d => d.region)
	
	/*let svg = d3.select('#location_epicurves')
	    .selectAll('svg')
	    .data(data_by_locations)
	    .enter()
	    .append('svg')
	    .attr("class", (d) => { return d.value[0].location_id})*/
	// loop over the data and create the bars
	data_by_locations.forEach( (d, i) => {
	  d.forEach( (datum) => {
	    datum.date = this.epicurveFigure.parseTime(datum.time_left)
	  })

	  d.total_suspected = d3.sum(d.map(d => d.suspected_cases))
	})
	  
	//yScale.domain([0, (d3.max(d, (d) => d.suspected_cases))])

	d3.select("#locationEpicurves").selectAll('*').remove()
	let svg = d3.select("#locationEpicurves")
	    .selectAll("uniqueChart")
	    .data(data_by_locations) // use d.value to get to the the_value
	    .enter()
	    .append('svg')
	    .attr("id", (d, i) => {
	      return `mini${d[1][0].parent_id}`
	    })
	    .attr("width", width + margin.left + margin.right)
	    .attr("height", height + margin.top + margin.bottom)
	    .append("g")
	    .attr("transform",
		  "translate(" + margin.left + "," + margin.top + ")");
	
	const lilx = d3.scaleLinear()
	      .domain(d3.extent(data.location_epicurves, (d) => { return d.date }))
	      .range([ 0, width ])

	svg
	  .append("g")
	  .attr("transform", `translate(0, ${height})`)
	  .call(d3.axisBottom(lilx).ticks(0))

	 //Add Y axis
	const lily = d3.scaleLinear()
	      .domain([0, d3.max(data.location_epicurves, (d) => { return d.suspected_cases })])
	      .range([ height, 0 ]);
	svg.append("g")
	  .call(d3.axisLeft(lily).ticks(5))

	svg
	  .append("text")
	  .attr("text-anchor", "start")
	  .attr("y", -5)
	  .attr("x", -20)
	  .text(function(d){ return(d[0])})
	  .style("font-size", "120%")
	  .style("font-weight", "bold")
	  .style("fill", "#1d685e")	
	  //.style("fill", function(d){ return color(d[0]) })

	svg
	  .append("path")
	  .attr("fill", "none")
	  .attr("stroke", '#000')
	  .attr("stroke-width", 1.9)
	  .attr("d", function(d){
            return d3.line()
              .x(function(d) { return lilx(d.date) })
              .y(function(d) { return lily(d.suspected_cases) })
          (d[1])
	  })
      })
  }
	     
  renderRangeSlider (data) {
    let dates = data.map( d => d.date)
    let sliderTicks = Math.round(Object.keys(dates).length / 50)
    
    let sliderRange = d3
	.sliderBottom()
	.min( d3.min(dates) )
	.max( d3.max(dates) )
	.width(800)
	.tickFormat(this.epicurveFigure.formatTime)
	.ticks(sliderTicks)
	.default([d3.min(dates), d3.max(dates)])
	.fill('#2196f3')
	.on('end', val => {
	  d3.select('p#value-range').text(val.map(this.epicurveFigure.formatTime).join(' - '));

	  this.renderEpicurve( Number.isInteger(val[0]) ? Math.round(val[0] / 1000): '',
			       Number.isInteger(val[1]) ? Math.round(val[1] / 1000): '' )
	})
    
    var gRange = d3
	.select('div#slider-range')
	.append('svg')
	.attr('width', 1000)
	.attr('height', 100)
	.append('g')
	.attr('transform', 'translate(30,30)');
    
    gRange.call(sliderRange);
    
    d3.select('p#value-range').text(
      sliderRange
	.value()
	.map(this.epicurveFigure.formatTime)
	.join(' - ') )

    this.epicurveFigure.sliderRendered = true
  }

  disableAutocompletion() {
    $('.no-autocomplete').attr('autocomplete', 'off')
  }
  
  submitFilterForm() {
    Rails.fire(this.filterFormTarget, 'submit')
  }

  clearFilterForm() {
    $(this.filterFormTarget).find(":not(#filter-submit-button) :input").val('')
    //$(this.filterFormTarget).find(":input").val('')
    $(this.filterFormTarget).find('input:checkbox').prop('checked', false)
    Rails.fire(this.filterFormTarget, 'submit')
  }
  
  onNewObservationCollectionError(event) {
    let [data, status, xhr] = event.detail;
    this.ocFormContainerTarget.innerHTML = xhr.response
  }

  onObservationsUpload(event) {
    if (event.detail.success) {
      document.addEventListener("turbo:load", () => {
	this.openFileList()

	let uploadedCollapse = new bootstrap.Collapse(this.uploadedAlertTarget)
	console.log(uploadedCollapse)
	uploadedCollapse.show()

	this.uploadedAlertTarget.scrollIntoView()
      })
    }
  }

  openFileList() {
    $('#observations-files-row').show()
    
    let openCollapse = new bootstrap.Collapse(this.openFileListButtonTarget)
    let closeCollapse = new bootstrap.Collapse(this.closeFileListButtonTarget)

    openCollapse.show()
    closeCollapse.hide()
  }

  closeFileList() {
    $('#observations-files-row').hide()

    let openCollapse = new bootstrap.Collapse(this.openFileListButtonTarget)
    let closeCollapse = new bootstrap.Collapse(this.closeFileListButtonTarget)

    openCollapse.hide()
    closeCollapse.show()
  }
  
  onFilterUpdate(event) {
    super.initializeDatepickers()

    this.applyDataTable()    
  }

  beforeObservationUpdate(event) {
    event.srcElement.closest('.observation-row').querySelectorAll('input').forEach(input => {
      input.setAttribute('disabled', true)
    })
  }
  
  onObservationUpdate(event) {
    super.disableAutocompletion()
  }
  
  updateObservation(event) {
    event.stopImmediatePropagation()
    event.preventDefault()

    let element = $(event.srcElement)

    Rails.fire($(element).closest('form').get(0), 'submit')
  }

  insertNewObservationRow(event) {
    let last_row = $('#editable-observation-list .observation-row').last()
    
    if (last_row) {
      $('#new-observation-row .area-col').html(last_row.find('.area-col').html())
    }

    $('#new-rows').append($('#new-observation-row').html())

    super.disableAutocompletion()
  }

  onLocationSelectorChange(event) {
    this.locationSelectionFormTarget.elements['change_depth'].value = $(event.srcElement).closest('.selector-span').find('#depth').val()

    Rails.fire(this.locationSelectionFormTarget, 'submit')
  }

  addNewLocation(event) {
    Rails.fire(this.locationSelectionFormTarget, 'submit')
  }

  onSubregionAdded(event) {
    if (this.locationSelectionFormTarget.elements['new_region'].value != '') {
      $(this.addLocationSpanTarget).show()
    } else {
      $(this.addLocationSpanTarget).hide()
    }
  } 

  primaryTimeCriteriaChanged(event) {
    $('#primary-time-criteria-other-wrapper').toggle($(event.srcElement).val() == 'OTHER')
  }

  primaryTestTypeChanged(event) {
    $('#primary-test-type-other-wrapper').toggle($(event.srcElement).val() == 'OTHER')
  }
  
  setObservationCollectionLocation(event) {

  }

  onBeforeObservationDelete(event) {
    this.elementToDelete = event.currentTarget.closest('.observation-row');
  }

  removeObservation(event) {
    $(event.srcElement).closest('.observation-row').remove()
    event.preventDefault()
  }
  
  destroyObservation(event) {
    if (this.elementToDelete) {
      this.elementToDelete.remove();
    }
  }

  onDestroyAllObservationsSuccess(event) {
    let [data, status, xhr] = event.detail

    $('#observation-list').html(xhr.response)
  }

  editableObservationsUpdated(event) {
    let [data, status, xhr] = event.detail
    $('#editable-observation-list').html(xhr.response)
    //this.editableObservationsTarget.innerHTML = xhr.response
  }

  onRemoveSourceDocument(event) {
    $(event.srcElement).closest('.source-document-row').remove()
  }

  appendSourceDocumentRow(event) {
    let [data, status, xhr] = event.detail

    $('#source-document-rows').append(xhr.response)
  }
}
