import "@hotwired/turbo-rails"

import './add_jquery'

import "bootstrap"

import 'jquery-ui/dist/jquery-ui'

import Rails from '@rails/ujs'
window.Rails = Rails
Rails.start()

require("@rails/activestorage").start()

import "./global"

import "./controllers"

