import { Controller } from "@hotwired/stimulus"
import DataTable from 'datatables.net-bs5'

export default class extends Controller {
  static targets = [ 'locationDocumentsTable']

  initialize() {
    if (this.hasLocationDocumentsTableTarget) {
      let id = this.locationDocumentsTableTarget.id
      
      $(`#${id}`).dataTable({
	order: [],
	retrieve: true,
	paging: false,
	searching: false,
	scrollX: '100%',
	bInfo: false,
	stateSave: true})
    }
  }
}
