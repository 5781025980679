//require('bootstrap-autocomplete/dist/latest/bootstrap-autocomplete')
import Autocomplete from "bootstrap5-autocomplete";

import * as bootstrap from "bootstrap"

window.App = {}

document.addEventListener("turbo:load", function() {
  if ( document.getElementById("country_search") ) {
    Autocomplete.init();

    new Autocomplete(document.getElementById("country_search"),
		     {
		       suggestionsThreshold: 2,
		       server: '/locations/country_search',
		       liveServer: true,
		       valueField: 'value',
		       labelField: 'text',
		       onSelectItem: () => {
			 Rails.fire($(event.srcElement).closest('form').get(0), 'submit')
		       }
		     })
  }    

  $('.auto-submit').change(function() {
    return Rails.fire($(this).closest('form').get(0), 'submit');
  })  
  
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
  
  // let alert_list = document.querySelectorAll('.alert')

})
